import "./styles.less";

import React, { useContext, useEffect, useState } from "react";

import Head from "next/head";
import { useRouter } from "next/router";
import { Logo } from "shared-components/Components/Logo/web";
import { HeaderMenu } from "./HeaderMenu";
import { DrawerMobile } from "./DrawerMobile";
import { UserBox } from "shared-components/ViewFragments/Header/UserBox/web";
import { useWindowSize } from "shared-components/GlobalHooks/useWindowSize";
import { BannersHeader } from "shared-components/Components/Banners/BannersHeader/web";
import { ConfigStateContext } from "shared-components/Contexts/Configurations/context";
import { useWaitUsrInteraction } from "shared-components/GlobalHooks/web/useWaitUsrInteraction";
import { useUser } from "shared-components/Components/User/User.hook";
import { useOneSignal } from "shared-components/GlobalHooks/web/OneSignal.hook";
import { useFilters } from "shared-components/Components/Filters/Filters.hook";
import { Location } from "shared-components/Components/Filters/Location/newLocation";
import { useSearchResultsFilters } from "shared-components/ViewFragments/SearchResults/Sider/Filters/SearchResultsFilters.hook";

export const Header = ({ showSearchBar = true, showArrow = false, isSticky = true }) => {
	const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
	const router = useRouter()
	const screens = useWindowSize()
	const { onesignal_configuration,country_code } = useContext(ConfigStateContext);
	const { filters } = useFilters();
	const { error, changeFiltersAndUpdateURL } = useSearchResultsFilters(true,false);
	const { hasInteract } = useWaitUsrInteraction();
	const { isLoggedIn } = useUser()
	const { InitOneSignal, sendTags } = useOneSignal();	

	const dictionary = { 'UY': 'https://www.infocasas.com.uy', 'PY': 'https://www.infocasas.com.py', 'BO': 'https://www.infocasas.com.bo', 'PE': 'https://www.infocasas.com.pe' }

	const setLanguageRel = () => {
		if (router.pathname === '/home') {
			return Object.keys(dictionary).map((e, i) => {
				return <link key={`${e}-country-${i}`} rel="alternate" hrefLang={`es-${e}`} href={dictionary[e]} ></link>
			})
		}
		else {
			return <></>
		}
	}

	useEffect(() => {
		if (onesignal_configuration && onesignal_configuration.app_id && hasInteract) {
			InitOneSignal();
		}
	}, [onesignal_configuration, hasInteract]);

	useEffect(() => {
		if (isLoggedIn) {
			sendTags();
		}
	}, [isLoggedIn])

	return (
		<>
			<Head>
				{setLanguageRel()}
			</Head>

			<BannersHeader />

			<header className={`header_container ${!isSticky ? "not-sticky" : ""}`}>
				<Logo link />

				<HeaderMenu type="desktop" />
				{router.pathname==='/searchPage'?
				<Location
					onHeader={true}
					changeFilters={changeFiltersAndUpdateURL}
					currentEstate={filters?.estate_id ? filters.estate_id : null}
					currentNeighbourhood={filters?.neighborhood_id ? filters.neighborhood_id : null} />
					:
					<></>
				}
				{/* @TODO - sacar antd
				 */}
				{screens.lg && <UserBox />}

				<button className={country_code === "CO" ? "fincaRaiz-burguer-btn" : "burguer-btn"} onClick={() => setMobileMenuVisible(true)}>
					☰
				</button>

				<DrawerMobile visible={mobileMenuVisible} onClose={() => setMobileMenuVisible(false)} />
			</header>
		</>
	);
};



export default Header;
