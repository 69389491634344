import { ImagenOptimizada } from "shared-components/Components/Image/_new_web";
import { useCardImageGallery } from "./hook";
import "./styles.less";
import Image from 'next/image'
import { useImage } from "shared-components/Components/Image/Image.hook";
import { useEffect, useRef, useState } from "react";
import { ArrowIcon } from "shared-components/Components/CustomIcons/ArrowIcon/web";
import { CamaraIcon } from "shared-components/Components/CamaraIcon/web";
import { CardCtaLead } from "../CardCtaLead/CardCtaLead";
import { PropComponentMode } from "../../PropertyInterfaces";
import { formatTagEventDate } from "src/functions/dateFormats";

interface CardGallery {
	id: number,
	idProject?: number,
	mode?: PropComponentMode,
	image: string,
	youtube?: string,
	staticImage?: boolean,
	hover: boolean,
	preloaded?: boolean,
	width: number,
	height: number,
	isInListing?: boolean
}

export const CardImageGallery = ({
	id,
	idProject = null,
	mode = "auto",
	image,
	youtube = null,
	staticImage = false,
	hover,
	preloaded = false,
	width = 265.63,
	height = 200,
	isInListing = false,
}: CardGallery) => {
	const { video, imagen, gallery, called, getImages } = useCardImageGallery({
		id,
		image,
		youtube,
		mode,
	});
	const { getImagenOptimizada, notifyError } = useImage()
	const carouselRailRef = useRef(null)
	const [currentSlide, setSlide] = useState(0)
	const [totalSlides, setTotalSlides] = useState(0)

	useEffect(() => {
		if (gallery)
			setTotalSlides(gallery.length)
	}, [gallery])

	useEffect(() => {
		if (hover && !called) {
			getImages()
		}
	}, [hover])


	const next: React.MouseEventHandler<HTMLButtonElement> = (e) => {
		e.preventDefault()
		e.stopPropagation()
		moveSlide('next')
	}
	const prev: React.MouseEventHandler<HTMLButtonElement> = (e) => {
		e.preventDefault()
		e.stopPropagation()
		moveSlide('prev')
	}

	const moveSlide = (type = 'next') => {
		let reil = carouselRailRef.current
		let newSlide = currentSlide
		if (type == 'next') {
			newSlide = currentSlide + 1
			newSlide = newSlide >= totalSlides ? 0 : newSlide
		} else {
			newSlide = currentSlide - 1
			newSlide = newSlide < 0 ? totalSlides - 1 : newSlide
		}
		setSlide(newSlide)
		let translateVal = newSlide * (-1) * 100
		reil.style.transform = `translateX(${translateVal}%)`
	}

	return (
		<div className="card-image-gallery">
			{imagen &&
				<Image 
					src={getImagenOptimizada({ src: imagen, width: width, height: height })}
					alt="Image"
					layout="fill"
					priority={preloaded}
					unoptimized
					objectFit="cover"
					onError={() => notifyError({
						property_id: id,
						image_url: imagen,
						image_id: gallery[0].id
					})}
				/>
			}

			{totalSlides > 1 && typeof window != 'undefined' && hover && (
				<>
					<button onClick={prev} className="arrow prev"> <ArrowIcon type="left" /> </button>
					<div className="card-image-gallery_carousel" ref={carouselRailRef}>
						<div className={"gallery-image"}>{
							//	agrego un slide vacio para que se vea la primer img, el .filter saca la primer imagen para que no salga repetida 
						}</div>
						{gallery.filter((v, i) => i > 0).map((g, i) => {
							const lastItem = gallery.filter((v, i) => i > 0).length;

							return (
								<div className={"gallery-image"} key={"Gallery_" + i}>
									<ImagenOptimizada
										src={g.image}
										alt="Image"
										width={300}
										height={270}
										imageId={g.id}
										propertyId={id}
									/>
									<CardCtaLead id={id} mode={mode} isInListing={isInListing} show={lastItem === currentSlide} />
								</div>
							);
						})}
					</div>
					<button onClick={next} className="arrow next"> <ArrowIcon type="right" /> </button>

					<div className="card-gallery-pagination">
						<CamaraIcon /> <span> {currentSlide + 1} / {totalSlides} </span>
					</div>
				</>
			)}
		</div>
	)
}
