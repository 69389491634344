export const traductions = {
  PE: {
    'barrio': 'distrito',
  },
  CO: {
    "neighbourhood": 'Barrio',
    "state": 'Estado',
    "city": 'Ciudad',
    'commune': 'Comuna',
    'locality': 'Localidad',
  }
}