import "./styles.less"
import { useSearchResultsPagination } from "./SearchResultsPagination.hook"
import { useRouter } from 'next/router'
import { useContext } from "react";
import { ConfigStateContext } from "shared-components/Contexts/Configurations/context";
import { SearchResultDisplay } from "./ResultDisplay/web";

export const SearchResultsPagination = ({ error, loading, paginatorInfo, initialUrlUpdate, genUrlType, explicitFilters }) => {
	const {
		changePage,
		data: { currentPage, perPage, total, lastPage },
	} = useSearchResultsPagination(paginatorInfo, false, initialUrlUpdate, explicitFilters);
	const { country_code } = useContext(ConfigStateContext);

	const { asPath } = useRouter()

	if (error || loading) return null;

	const genPagURL = (page) => {
		let newPagURL = '';
		if (genUrlType && genUrlType === "query.pagina") {
			const getQueryParams = (params, url) => {
				let href = url;
				//this expression is to get the query strings
				let reg = new RegExp('[?&]' + params + '=([^&#]*)', 'i');
				let queryString = reg.exec(href);
				return queryString ? queryString[1] : null;
			}
			const pag_a = getQueryParams("pagina", asPath);
			newPagURL += asPath.replace("pagina=" + pag_a, "pagina=" + page)
			if (!newPagURL.includes("pagina")) {
				if (!newPagURL.includes("?")) {
					newPagURL += "?pagina=" + page
				} else {
					newPagURL += "&pagina=" + page
				}
			}

		} else {
			const [urlSegments, paramString] = asPath.split('?')
			let totalPages = Math.ceil(total / perPage)
			let basePath = urlSegments
				.replace(/pagina([0-9]+)?\/?$/gm, '')
				.split('/')
				.filter(e => e && e.length)

			if (page > 1 && page <= totalPages)
				basePath = [...basePath, `pagina${page}`]

			newPagURL = '/' + basePath.join('/')
			newPagURL = paramString ? newPagURL.concat('?', paramString) : newPagURL
		}

		return newPagURL
	}


	return (
		<div className="search-results-pagination">
			<SearchResultDisplay
				error={error}
				loading={loading}
				paginatorInfo={paginatorInfo}
			/>

			<ul className="list" role="navigation" aria-label="Pagination">
				{currentPage > 1 &&
					<li className={`ant-pagination-item ${country_code}`}>
						<a className={`ant-pagination-item-link ${country_code}`}
							href={genPagURL(currentPage - 1)}
							rel="nofollow"
							onClick={(e) => {
								if ((genUrlType && genUrlType === "query.pagina")) {
									return;
								}
								e.preventDefault()
								window.scrollTo(0, 0)
								changePage(currentPage - 1)
							}}
						> {'<'} </a>
					</li>
				}

				{generatePaginationArray(lastPage, currentPage).map((item, i) => {
					let isDotts = item == '+...' || item == '-...'
					let page = isDotts ? (item == '+...' ? currentPage + 4 : currentPage - 4) : item
					let cssClass = `ant-pagination-item ${country_code} ${!isDotts && currentPage == item ? 'ant-pagination-item-active' : ''}  `

					return <li className={cssClass} key={i}>
						{currentPage == item
							? <span className={`ant-pagination-item-link ${country_code}`} aria-label="current page">{page}</span>
							: <a className={`ant-pagination-item-link ${country_code}`}
								href={genPagURL(page)}
								aria-label={!isDotts ? `Page ${page}` : null}
								rel="nofollow"
								onClick={(e) => {
									if ((genUrlType && genUrlType === "query.pagina")) {
										return;
									}
									e.preventDefault()
									window.scrollTo(0, 0)
									changePage(page)
								}}
							>{isDotts ? '...' : page}</a>
						}

					</li>
				})}

				{currentPage < lastPage && currentPage < 50 &&
					<li className={`ant-pagination-item ${country_code}`} >
						<a className={`ant-pagination-item-link ${country_code}`}
							href={genPagURL(currentPage + 1)}
							rel="nofollow"
							onClick={(e) => {
								if ((genUrlType && genUrlType === "query.pagina")) {
									return;
								}
								e.preventDefault()
								window.scrollTo(0, 0)
								changePage(currentPage + 1)
							}}
						> {'>'} </a>
					</li>
				}
			</ul>
		</div>
	)
};

const generatePaginationArray = (totalPages, currentPage) => {
	let res = []
	// For Precaution
	const totalPagesNormalized = totalPages > 50 ? 50 : totalPages;
	currentPage = (Math.min(Math.max(0, currentPage), totalPagesNormalized - 1));

	if (totalPagesNormalized <= 12) {
		for (let i = 1; i <= totalPagesNormalized; i++) {
			res.push(i)
		}
		return res
	}

	if (currentPage <= 6) {
		for (let i = 1; i <= 7; i++) {
			res.push(i)
		}
		res = [...res, '+...', totalPagesNormalized]
	} else if (currentPage >= totalPagesNormalized - 4) {
		for (let i = totalPagesNormalized - 6; i <= totalPagesNormalized; i++) {
			res.push(i)
		}
		res = [1, '-...', ...res]
	} else {
		for (let i = currentPage - 2; i <= currentPage + 2; i++) {
			res.push(i)
		}
		res = [1, '-...', ...res, '+...', totalPagesNormalized]
	}

	return res
}