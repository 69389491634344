
import { getMode, isHighlighted, isHighlightedWithTag, isProject, isSuperHighlighted, showBathrooms, showBedrooms, showGuests, showM2, showRooms } from "@Utils/Functions"
import { CardImageGallery } from "../../Components/Property/PropertyCard/CardImageGallery/web"
import { useEffect, useState } from "react"
import { OwnerLogo } from "shared-components/Components/Property/OwnerLogo/web"
import { useContext } from "react"
import { ConfigStateContext } from "shared-components/Contexts/Configurations/context"
import "./ListingCard.less"

import dynamic from "next/dynamic"
import { LeadButtons } from "shared-components/Components/Property/InformationRequest/LeadButtons/web"
import { Favorite } from "shared-components/Components/Property/Favorite/web"
import { TagEvent } from "shared-components/Components/TagEvent/web"
import { firstLetterToUppercase, getProjectCommertialUnitsOrdered, getProjectRangePrices } from "src/utils/Functions"
import { PriceTag } from "shared-components/Components/Property/PriceTag/web"
import { ListingTypeTag } from "shared-components/Components/Property/ListingTypeTag/web"
import { HighlightBrokerIcon } from "shared-components/Components/CustomIcons/HighlightBrokerIcon/web"
import { HighlightBuilderIcon } from "shared-components/Components/CustomIcons/HighlightBuilderIcon/web"
import { ListingTag } from "src/contexts/ListingTags/ListingTagsProvider"
import useListingTags from "src/hooks/listingTags/useListingTags"
import { useFilters } from "shared-components/Components/Filters/Filters.hook"
import { CommercialUnit, Currency } from "shared-components"

//@ts-ignore
const FacilitiesCard = dynamic(
    () => import("shared-components/Components/Property/FacilitiesCard/web").then(mod => mod.FacilitiesCard),
    { ssr: false }
)

type Event = {
    tag: string
    show: boolean,
    link: string,
    logo: string,
    fecha_inicio_evento: string
}

type commonExpenses = {
    amount: number,
    hidePrice: boolean
}

export type ListingData = {
    id?: any,
    legacy_propID?: any,
    isProject?: any,
    img?: any,
    owner?: {
        logo?: any,
        name?: any,
        inmoLink?: any,
        inmoPropsLink?: any,
        inmofull?: any,
        particular?: boolean,
        type?: string,
        has_whatsapp?: boolean
    },
    seller?: any,
    price?: any,
    currency?: Currency,
    currency_id?: any,
    commercial_units: CommercialUnit[],
    hidePrice?: any,
    highlight?: any,
    property_type_id?: any,
    operation_type_id?: any,
    bedrooms?: any,
    bathrooms?: any,
    rooms?: any,
    m2?: any,
    guests?: any,
    title?: any,
    description?: any,
    locations?: any,
    facilities?: any,
    link?: any,
    isExternal?: any,
    event?: Event,
    tag?: any,
    commonExpenses?: commonExpenses,
    commonExpenses_currency?: string,
    project?: any
}

type ListingCardProps = {
    preloadImg?: boolean
    data: ListingData,
    onCancel?: () => void | null,
    hideTags?: boolean,
    hideCta?: boolean,
    staticGallery?: boolean,
    showExpenses?: boolean,
    isFromListing?: boolean
}

export const ListingCard = ({ data, preloadImg = false, onCancel, hideTags = false, hideCta = false, staticGallery = false, showExpenses = false, isFromListing = false }: ListingCardProps) => {
    const { country_code, translations } = useContext(ConfigStateContext)
    const { listingTags } = useListingTags();
    const { filters } = useFilters();

    const [idealFilters, setIdealFilters] = useState<any>(false)
    const [dataTags, setDataTags] = useState<ListingTag>({} as ListingTag)
    const [hover, setHover] = useState(false)
    const [lowerPriceUnit, setLowerPriceUnit] = useState<CommercialUnit>(data.commercial_units ? data.commercial_units[0] : {} as CommercialUnit)

    const isSuperHigh = isSuperHighlighted(data.highlight)
    const isHighlight = isHighlighted(data.highlight)
    const prices = getProjectRangePrices({ listing: data, filters })

    const onHover = (e?: any) => {
        if (!staticGallery) setHover(true);
    }
    const onHoverEnd = (e?: any) => {
        if (!staticGallery) setHover(false);
    }

    const bedrooms = () => {
        const propertyIsProject = isProject(data);
        const dataBedroom = propertyIsProject && lowerPriceUnit ? lowerPriceUnit.bedrooms : data.bedrooms

        if (showBedrooms([data.property_type_id], data.operation_type_id, country_code) && dataBedroom > 0) {
            return (
                <strong>{dataBedroom == 0 || dataBedroom === undefined ? 'Mono' : dataBedroom + " " + firstLetterToUppercase(translations.hab) + "s."} </strong>
            )
        } else return null;
    }

    const bathrooms = () => {
        const propertyIsProject = isProject(data);
        const dataBathroom = propertyIsProject && lowerPriceUnit ? lowerPriceUnit.bathrooms : data.bathrooms

        if (showBathrooms([data.property_type_id], data.operation_type_id, country_code) && dataBathroom > 0) {
            return (
                <strong>{dataBathroom} {dataBathroom > 1 ? "Baños" : "Baño"}</strong>
            )
        } else return null;
    }

    const m2 = () => {
        const propertyIsProject = isProject(data);
        const dataM2 = propertyIsProject && lowerPriceUnit ? lowerPriceUnit.m2 : data.m2

        if (showM2(data.operation_type_id) && dataM2 > 0) {
            return <strong>{dataM2} m²</strong>
        } else return null;
    }

    const ids = ["190663135"]

    useEffect(() => {
        if (!isProject(data) && data && 'operation_type_id' in data && 'property_type_id' in data) {
            setIdealFilters({
                operation_type_id: data.operation_type_id,
                property_type_id: [data.property_type_id],
                maxPrice: data.price + (data.price * 0.20),
                bedrooms: [data.bedrooms],
                currencyID: Number(data.currency_id)
            })
        }
    }, [])

    useEffect(() => {
        if (!!listingTags.length && data.id) {
            const currentTags = listingTags.filter((tag) => tag.id === data.id)[0]
            setDataTags(currentTags)
        }
    }, [listingTags, data])

    useEffect(() => {
        if (data.id) {
            const unitsOrdered = getProjectCommertialUnitsOrdered({ listing: data, filters });

            if (unitsOrdered && !!unitsOrdered.length) setLowerPriceUnit(unitsOrdered[0])
        }
    }, [data, filters])

    if (!data || !data.id) return null;

    return (
        <div onMouseEnter={onHover} onMouseLeave={onHoverEnd} className={`listingCard ${isSuperHigh ? 'isSuperHighlighted' : ''} ${data.highlight == 17 ? 'gold' : ''} ${(isHighlight && isFromListing && data.highlight !== 21) && "highlight"} ${country_code} ${ids.includes(data.id.toString()) ? "special" : ""}`} >
            <CardLink data={data} className="lc-cardCover" onCancel={onCancel}>
                <div className="lc-galleryWrap">
                    <CardImageGallery
                        id={data.legacy_propID}
                        idProject={data.id}
                        mode={getMode(isProject(data))}
                        image={data.img}
                        hover={hover}
                        preloaded={preloadImg}
                        width={500}
                        height={isSuperHigh ? 386 : 260}
                    />
                </div>

                <div className="lc-cardCoverTop">
                    <div style={{ flex: 1 }}>
                        {(!hideTags && data.id !== "" && dataTags && dataTags.id) && <ListingTypeTag id={data.id} data={dataTags} />}
                    </div>
                    <div>
                        {!isProject(data) && <Favorite id={data.legacy_propID} type="justIcon" />}
                    </div>
                </div>

                {(isProject(data) && data.event.show && data.event.logo !== "" && data.event.tag !== "" && data.event.link !== "") ?
                    <TagEvent event={data.event} /> : null
                }
            </CardLink>

            <div className="lc-dataWrapper">
                {isSuperHigh && country_code != 'ce3' &&
                    <OwnerLogo
                        logo={data.owner.logo}
                        name={data.owner.name}
                        inmoLink={data.owner.inmoLink}
                        inmoPropsLink={data.owner.inmoPropsLink}
                        inmoFull={data.owner.inmofull}
                    />
                }
                <CardLink data={data} className="lc-data" onCancel={onCancel}>
                    <div className="lc-price">
                        {data.currency && data.price && !data.hidePrice
                            ? <span className="heading heading-4 high">
                                <PriceTag
                                    commonExpenses={{
                                        amount: data.commonExpenses?.amount,
                                        currency: {
                                            name: data.commonExpenses_currency
                                        },
                                        hide: data.commonExpenses?.hidePrice
                                    }}
                                    price={
                                        isProject(data) ?
                                            {
                                                ...prices.minPrice,
                                                hidePrice: false,
                                            }
                                            : data.price
                                    }
                                    isProject={isProject(data)}
                                    showExpenses={showExpenses}
                                />

                                {country_code === "ce3" && <span className="qty-price">/noche</span>}
                            </span>
                            : <strong>Consultar</strong>
                        }
                    </div>
                    <div className="lc-typologyTag">
                        <span className="body body-2 body-regular medium">
                            {bedrooms()}

                            {(showRooms([data.property_type_id], data.operation_type_id, country_code) && data.rooms > 0) &&
                                <strong>{data.rooms == 1 ? data.rooms + ' Ambiente' : data.rooms + ' Ambientes'} </strong>
                            }

                            {bathrooms()}

                            {m2()}

                            {showGuests(data.operation_type_id) &&
                                <strong>{data.guests} Huéspedes</strong>
                            }
                        </span>
                    </div>
                    <span className="lc-title body body-2 body-regular medium">{data.title}</span>

                    {isSuperHigh &&
                        <p className="lc-description">{data.description}</p>
                    }

                    <strong className="lc-location body body-1 body-bold medium">
                        {getLocationLabel(data)}
                    </strong>

                    <div className="publisher">
                        {(data.project && data.project.id) && (
                            <span className="body body-2 body-bold high d-block"><strong>{data.project.title}</strong></span>
                        )}

                        <span className="d-block">
                            {(data.owner?.type &&
                                (data.owner?.type === "inmobiliaria" || data.owner?.type === "desarrollador")) ?
                                <span><span className="body body-2 medium">Por</span> <strong className="body body-2 high">{data.owner.name}</strong></span> :
                                <span className="body body-2 high">Anuncio particular</span>
                            }
                        </span>
                    </div>

                    {country_code === "ce3" &&
                        <div style={{ marginTop: '-10px', maxWidth: '268px' }}>
                            <FacilitiesCard facilities={data.facilities ?? []} dataFormat="idsArray" />
                        </div>
                    }
                    {!hideCta && <LeadButtons
                        id={data.legacy_propID}
                        mode={getMode(isProject(data))}
                        isInListing
                        idealFilters={idealFilters}
                        isProject={isProject(data)}
                        projectId={data.project?.id ?? ""}
                        owner={data.owner}
                        seller={data.seller}
                    />}
                </CardLink>
            </div>
        </div>
    )
}

const CardLink = ({ children, data, className = "", onCancel }) => {
    let link = (data.project && data.project.id) ? data.project.link : data.link
    let isExternal = data.isExternal

    //@hardcoded - pedido especial de hardcodeo, en algun momento habria que sacar esto y usar directamente data.link y data.isExternal en el render del componente
    if (isProject(data) && (data.id == 13755 || data.legacy_propID == 189503363)) {
        link = 'https://www.campiglia.com.uy/propiedades_destacadas'
        isExternal = true
    }
    //END @hardcoded - pedido especial de hardcodeo
    const clickHandler = (e: any) => {
        const isOverlayClick = e.target.classList.contains('overlay-cta') || e.target.closest('.overlay-cta');

        if (onCancel) onCancel();

        if (isOverlayClick) {
            e.preventDefault();
        } else if (data.isExternal) {
            e.preventDefault();

            let link = (data.project && data.project.id) ? data.project.link : data.link;

            window.open(
                link,
                "_blank",
                "location=yes,height=670,width=620,scrollbars=yes,status=yes"
            );
        }
    }

    return <a className={className} target="_blank" title={data.title}
        href={!data.isExternal ? link : '#'}
        rel={isExternal ? 'nofollow' : null}
        onClick={clickHandler}
    >
        {children}
    </a>
}

export const genTagsText = (data, country_code) => {
    if (data && data.id === "") return null

    let tags = []
    if (country_code == 'ce3' && data.seaDistanceName !== null)
        tags.push({
            name: "seaDistance",
            text: `${data.seaDistanceName} ${data.seaDistanceName != "frente al mar" ? "del mar" : ''}`,
            icon: null
        })

    if (data.isProject) tags.push({ name: "isProject", text: "Proyecto", icon: null })
    if (data.owner.particular) tags.push({ name: "particular", text: "Usado", icon: null })
    else if (data.owner.type === "inmobiliaria") tags.push({ name: "particular", text: "Inmobiliaria", icon: null })

    if (data.highlight) {
        if (isSuperHighlighted(data.highlight)) {
            tags.push({ name: "isSuperHighlighted", text: 'Super Destacado', icon: null })
        } else if (isHighlightedWithTag(data.highlight, country_code)) {
            const isGold = data.highlight === 17
            const text = isGold ? 'Destacado Gold' : 'Destacado'

            tags.push({
                name: "isHighlighted", text, icon: data.highlight === 20 ?
                    <HighlightBuilderIcon /> : <HighlightBrokerIcon gold={isGold ? "true" : "false"} />
            })
        }
    }

    if (data.isExternal) tags.push({ name: "isExternal", text: "Ficha externa", icon: null })
    if (data.tour3d) tags.push({ name: "tour3d", text: "Tour Virtual", icon: null })

    return tags
}

export const getLocationLabel = (data) => {
    let label = ""
    const { filters } = useFilters();

    if (filters?.locations) {
        const currentFilters = filters.locations[0]

        if (['ZONE', 'COMMUNE', 'NEIGHBOURHOOD', 'LOCALITY'].includes(currentFilters.type)) {
            label = currentFilters.name;

            if (currentFilters.city && currentFilters.city.length > 0) {
                label += ", " + currentFilters.city[0]?.name;
            }
        }

        if (['CITY'].includes(currentFilters.type)) {
            label = currentFilters.name;

            if (currentFilters.estate) {
                label += ", " + currentFilters.estate?.name;
            }
        }

        if (['STATE'].includes(currentFilters.type)) {
            label = currentFilters.country && currentFilters.country.length ? currentFilters.name + ', ' + currentFilters.country[0]?.name : currentFilters.name;
        }
    } else {
        const mainLocation = data.locations?.location_main;
        const city = data.locations?.city[0]?.name;
        const state = data.locations?.state[0]?.name;
        const neighbourhood = data.locations?.neighbourhood[0]?.name;
        const commune = data.locations?.commune[0]?.name;
        const locality = data.locations?.locality[0]?.name;
        const zone = data.locations?.zone[0]?.name;

        if (mainLocation && !!Object.keys(mainLocation).length) {
            if (["neighbourhood", "commune", "zone", "locality"].includes(mainLocation.location_type)) {
                return `${mainLocation.name}, ${city}, ${state}`;
            }
            if (mainLocation.location_type === "city") {
                return `${mainLocation.name}, ${state}`;
            }
            if (mainLocation.location_type === "state") {
                return mainLocation.name;
            }
        } else if (neighbourhood && !!Object.keys(neighbourhood).length) {
            return `${data.locations.neighbourhood[0].name}, ${city}, ${state}`;
        } else if (commune && !!Object.keys(commune).length) {
            return `${commune}, ${city}`
        } else if (locality && !!Object.keys(locality).length) {
            return `${locality}, ${city}, ${state}`;
        } else if (zone && !!Object.keys(zone).length) {
            return `${zone}, ${city}, ${state}`;
        } else if (city && !!Object.keys(city).length) {
            return `${city}, ${state}`;
        } else if (state && !!Object.keys(state).length) {
            return state;
        }
        else {
            return "Colombia"
        }
    }

    return label
}