import React, { useState, useEffect, Fragment, useContext } from "react";
import "./styles.less";
import { KeywordLocation } from "../KeywordLocation/web";
import { useHomeFilters } from "shared-components/ViewFragments/Home/HomeFilters/HomeFilters.hook";

export const Location = ({ ...props }: any) => {
    const { changeFilters } = useHomeFilters();
    const [location, setLocation] = useState([]);

    const handleLocations = data => {
        setLocation(data);
    };

    return (
        <div className={`estate-list-container ${props.onHeader ? 'state-list-header-container' : ''}`}>
            <KeywordLocation
                filterChanged={changeFilters}
                searchByReference={true}
                searchByProject={false}
                handleLocations={handleLocations}
                locations={location}
                isSearchForHome={true}
                className="location-listing"
            />
        </div>
    );
};
